import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { Row, Col } from 'reactstrap';
import {
  Fonts,
  HeroWP,
  ContentWp,
  ShadowSec,
  FindSection,
  PageBody,
  ResearchPipelineSection,
} from '../global.css';
import Video from '../components/player/wistia';
import Img from 'gatsby-image';
import { getCompanyData, replaceCompanyName } from '../constants/config';
import Modal from '../components/modal/modal';
import Redirect from '../components/redirect/redirect';
import Loading from '../components/loading/loading';
import { registerTrackEvent } from '../helpers/tagManager';

const WhyParticipate = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [scriptsLoading, setScriptsLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [flip, setFlip] = useState(
    data.whyParticipateEnJson.reasonSecContent.map(() => {
      return true;
    })
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScriptsLoading(false);
    }
  }, []);

  const loading = () => {
    setPageLoading(false);
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const toggle = (itemIndex) => {
    if (
      typeof window.orientation === 'undefined' ||
      navigator.userAgent.indexOf('Mobile') < 0
    ) {
      let localFlip = flip;
      setFlip(
        localFlip.map((value, index) => (index != itemIndex ? true : !value))
      );
    }
  };

  const toggleMobile = (itemIndex) => {
    if (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('Mobile') > -1
    ) {
      let localFlip = flip;
      setFlip(
        localFlip.map((value, index) => (index != itemIndex ? true : !value))
      );
    }
  };

  return scriptsLoading ? (
    ''
  ) : (
    <PageBody>
      <div className={!pageLoading ? 'hidden' : ''}>
        <Loading />
      </div>
      <div className={pageLoading ? 'hidden' : ''}>
        <Layout meta={replaceCompanyName(data.whyParticipateEnJson, 'seo').seo}>
          <div className={'content-container'}>
            <Fonts>
              <HeroWP>
                <Row>
                  <Col lg={4} xs={12}>
                    <h1>{data.whyParticipateEnJson.title}</h1>
                  </Col>
                  <Col lg={8} xs={12}>
                    <Video
                      code={'dc209z9i1g'}
                      title={'Lung Cancer - Linnea'}
                      pageLoading={loading}
                    />
                    <p>{data.whyParticipateEnJson.permission}</p>
                  </Col>
                </Row>
              </HeroWP>
              <ContentWp>
                <Row>
                  <ShadowSec className={'participate'} />
                  <Col xs={12}>
                    <h2>{data.whyParticipateEnJson.reasonSecTitle}</h2>
                  </Col>
                  {data.whyParticipateEnJson.reasonSecContent.map(
                    (item, index) => (
                      <Col
                        className={'sec-box'}
                        key={index}
                        lg={3}
                        sm={6}
                        xs={12}
                        onMouseEnter={() => toggle(index)}
                        onMouseLeave={() => toggle(index)}
                        onClick={() => toggleMobile(index)}
                      >
                        {flip[index] ? (
                          <Img
                            fluid={item.img.childImageSharp.fluid}
                            alt={'test'}
                            durationFadeIn={0}
                            loading="eager"
                          />
                        ) : (
                          <div
                            className={'content-box'}
                            dangerouslySetInnerHTML={{
                              __html: item.content.childMarkdownRemark.html,
                            }}
                          />
                        )}
                      </Col>
                    )
                  )}
                </Row>
              </ContentWp>
              <FindSection>
                <Row>
                  <Col className={'find-section left'} sm={6} xs={12}>
                    <a
                      href={'/en/find-a-trial/'}
                      onClick={registerTrackEvent({
                        category: 'SiteSearch',
                        action: 'Open',
                        label: getCompanyData(
                          data.whyParticipateEnJson,
                          'findTrial'
                        ),
                      })}
                    >
                      {getCompanyData(data.whyParticipateEnJson, 'findTrial')}
                    </a>
                  </Col>
                  <Col className={'find-section right'} sm={6} xs={12}>
                    <Modal
                      button={data.whyParticipateEnJson.findOtherTrial}
                      hideModal={hideModal}
                      showModal={showModal}
                      open={modalOpen}
                      type={'redirect'}
                      trackEvent={{
                        label: 'Redirect Popup',
                      }}
                    >
                      <Redirect
                        hideModal={hideModal}
                        url={'https://www.clinicaltrials.gov'}
                      />
                    </Modal>
                  </Col>
                </Row>
              </FindSection>
              <ResearchPipelineSection>
                <div className={'container participate'}>
                  <span>{'Learn more about'}</span>
                  <a
                    href={getCompanyData(
                      data.whyParticipateEnJson,
                      'researchPipeline'
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {'research pipeline'}
                  </a>
                </div>
              </ResearchPipelineSection>
            </Fonts>
          </div>
        </Layout>
      </div>
    </PageBody>
  );
};

WhyParticipate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WhyParticipate;

export const query = graphql`
  query WhyParticipate {
    whyParticipateEnJson {
      seo {
        pageTitle
        pageDescription
      }
      title
      permission
      reasonSecTitle
      findTrial
      findTrial_merck
      researchPipeline
      researchPipeline_merck
      findOtherTrial
      reasonSecContent {
        img {
          childImageSharp {
            fluid(maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
